import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';
import { useMutation } from 'urql';
import { useDispatch } from 'react-redux';

import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevel, ServiceLevelFn } from '@serenityapp/core';
import { UserApi } from '@serenityapp/api-graphql';
import { UsersUpdateOutput, UsersUpdateVariables } from '@serenityapp/domain';
import { snackAdd } from '@serenityapp/redux-store';

import { BULLET_POINT, MULTI_PURPOSE_SERVICE_LEVEL } from '../utils';
import { UnitFormValues } from '../schema';
import { useMakeTestId } from '@serenityapp/components-react-web';

const UPDATE_RESIDENT_SERVICE_LEVEL = 'updateResidentServiceLevel';
const UPDATE_LOCATION_SERVICE_LEVEL = 'updateLocationServiceLevel';
const CONVERT_LOCATION_MULTI_PURPOSE = 'convertLocationMultiPurpose';
const DO_NOTHING = 'doNothing';

/**
 * This dialog gets open when service level mismatch occur between selected service level for unit
 * and service levels of users selected in the form.
 * @returns a dialog component
 */
const ServiceLevelMismatchDialog = () => {
  const makeTestId = useMakeTestId('ServiceLevelMismatchDialog');
  const mainTestId = makeTestId('');
  const dispatch = useDispatch();
  const { values, setFieldValue, dirty } = useFormikContext<UnitFormValues>();

  const [currentRadioGroupValue, setCurrentRadioGroupValue] = useState<string>(DO_NOTHING);

  const [mismatchData, setMismatchData] = useState<{
    users: SelectorItemProps[];
    serviceLevel?: string;
  }>();

  const closeDialog = () => {
    setMismatchData(undefined);
    setCurrentRadioGroupValue(DO_NOTHING);
  };

  const handleDoNothing = () => {
    closeDialog();
  };

  const [usersUpdateResult, usersUpdate] = useMutation<UsersUpdateOutput, UsersUpdateVariables>(
    UserApi.userBatchUpdateMutation,
  );

  const isLoading = usersUpdateResult.fetching;

  const onChangeRadioGroupValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentRadioGroupValue(event.target.value);
  };

  const updateResidentServiceLevel = () => {
    if (!mismatchData?.users || !mismatchData?.users.length) return;
    usersUpdate({
      input: {
        users: mismatchData?.users.map((user) => ({
          id: user.id,
          serviceLevel: mismatchData?.serviceLevel as ServiceLevel,
        })),
      },
    }).then((result) => {
      if (!result.error) {
        dispatch(
          snackAdd({
            message: `Service level has been changed to care recipient(s).`,
            type: 'success',
          }),
        );
        const newUsers = (values.users as SelectorItemProps[]).map((user) => ({
          ...user,
          subtitle: ServiceLevelFn.abbreviation(mismatchData.serviceLevel),
        }));
        setFieldValue('users', newUsers);
        closeDialog();
      } else {
        dispatch(
          snackAdd({
            message: `Something went wrong while switching service level to care recipient(s)`,
            type: 'error',
          }),
        );
        if (result.data?.users && result.data.users.length > 0) {
          const newUsers = (values.users as SelectorItemProps[]).map((user) => {
            const isUpdated = result?.data?.users?.some(
              (updatedUser) => user.id === updatedUser.id,
            );
            if (isUpdated) {
              return {
                ...user,
                subtitle: ServiceLevelFn.abbreviation(mismatchData.serviceLevel),
              };
            }
            return user;
          });
          setFieldValue('users', newUsers);
        }
      }
    });
  };

  const updateLocationServiceLevel = () => {
    if (!mismatchData) return;
    const userServiceLevel = mismatchData.users[0].subtitle?.split(BULLET_POINT)[0].trim();
    setFieldValue('serviceLevel', ServiceLevelFn.getFromString(userServiceLevel));
    closeDialog();
  };

  const convertLocationMultiPurpose = () => {
    if (!mismatchData) return;
    setFieldValue('serviceLevel', MULTI_PURPOSE_SERVICE_LEVEL);
    closeDialog();
  };

  const handleUpdate = () => {
    if (!mismatchData) return;
    switch (currentRadioGroupValue) {
      case UPDATE_RESIDENT_SERVICE_LEVEL:
        updateResidentServiceLevel();
        break;
      case UPDATE_LOCATION_SERVICE_LEVEL: {
        updateLocationServiceLevel();
        break;
      }
      case CONVERT_LOCATION_MULTI_PURPOSE:
        convertLocationMultiPurpose();
        break;
      case DO_NOTHING:
        handleDoNothing();
        break;
      default:
        break;
    }
  };

  // This is the main logic of the component. This use effect gets fired whenever the service level
  // of unit changes or some user gets assigned to it. It checks if there is service level mismatch
  // between those and if there is, it sets mismatch data which are than used to display and use
  // dialog
  useEffect(() => {
    // We do not want to validate the service level if the form was not touched yet
    if (!dirty) return;

    if (
      values.serviceLevel &&
      values.serviceLevel !== MULTI_PURPOSE_SERVICE_LEVEL &&
      values.users
    ) {
      // check if there are any users with different service levels currently selected
      // if so, open dialog
      const usersWithMismatchedServiceLevel = (values.users as SelectorItemProps[]).filter(
        (user) =>
          !user.subtitle?.includes(
            ServiceLevelFn.abbreviation(ServiceLevelFn.getFromString(values.serviceLevel)),
          ),
      );

      if (usersWithMismatchedServiceLevel.length > 0) {
        setMismatchData({
          users: usersWithMismatchedServiceLevel,
          serviceLevel: values.serviceLevel,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.serviceLevel, values.users]);

  return (
    <Dialog data-testid={mainTestId} open={!!mismatchData}>
      <DialogTitle>Service level mismatch</DialogTitle>
      <DialogContent>
        <Box>
          The resident's current service level does not match the designated service level for
          this unit.
        </Box>
        <Box sx={listSx}>
          <Box data-testid={makeTestId('mismatch-users')}>
            {mismatchData?.users?.map((user) => (
              <Box key={user.id}>
                {user.label} - {user.subtitle}
              </Box>
            ))}
          </Box>
          <Box data-testid={makeTestId('mismatch-unit')}>
            {values.locationName ? values.locationName : 'Unit'} -{' '}
            {ServiceLevelFn.abbreviation(mismatchData?.serviceLevel)}
          </Box>
        </Box>
        <Box>To proceed, you have the following options:</Box>
        <RadioGroup
          data-testid={makeTestId('radio-group')}
          defaultValue={DO_NOTHING}
          value={currentRadioGroupValue}
          onChange={onChangeRadioGroupValue}
        >
          <FormControlLabel
            control={<Radio color="primary" data-testid={makeTestId('do-nothing')} />}
            label="Do nothing"
            value={DO_NOTHING}
          />
          <FormControlLabel
            control={
              <Radio color="primary" data-testid={makeTestId('update-resident-service-level')} />
            }
            label="Update Resident’s Service Level to match Unit’s Service Level"
            value={UPDATE_RESIDENT_SERVICE_LEVEL}
          />
          <FormControlLabel
            control={
              <Radio color="primary" data-testid={makeTestId('update-location-service-level')} />
            }
            label="Update Unit’s Service Level to match Resident’s Service Level"
            value={UPDATE_LOCATION_SERVICE_LEVEL}
          />
          <FormControlLabel
            control={
              <Radio color="primary" data-testid={makeTestId('update-location-multi-purpose')} />
            }
            label="Convert unit to Multi-Purpose (Allows for Multiple Service Levels)"
            value={CONVERT_LOCATION_MULTI_PURPOSE}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" data-testid={makeTestId('cancel')} onClick={handleDoNothing}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          data-testid={makeTestId('update')}
          loading={isLoading}
          variant="contained"
          onClick={handleUpdate}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  mt: 2,
  mb: 2,
  typography: 'body1m',
};

export default ServiceLevelMismatchDialog;
