import { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core';
import { Provider as ReduxProvider } from 'react-redux';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink } from '@apollo/client';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { EnhancedStore } from '@reduxjs/toolkit';
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import * as Sentry from '@sentry/react';
import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';

import { ApiGraphql } from '@serenityapp/api-graphql';
import type { ISentry } from '@serenityapp/core';
import { NullAnalytics } from '@serenityapp/core';
import { createErrorMonitoringLink, NullCache } from '@serenityapp/components-react-common';
import { IApiClient } from '@serenityapp/core-graphql';
import { CognitoFn } from '@serenityapp/core-cognito';
import { AwsFn } from '@serenityapp/core-aws';
import { ThemeProvider, theme } from '@serenityapp/components-react-web';
import { AnalyticsFrontendClientKinesis } from '@serenityapp/core-kinesis';

import { configureStore } from './store/configure-store';
import { createStorageClientAndOutbox } from './store/storage';
import { deviceAttributes, versionAttributes } from './analytics';
import { getViewerState, PubSub, viewerSignOut } from '@serenityapp/redux-store';
import { GlobalContext } from './providers';
import AppRoutes from './routes/AppRoutes';
import { buffers, EventChannel, eventChannel } from 'redux-saga';
import { onError } from '@apollo/client/link/error';
import { FORBIDDEN_ERROR_IP, FORBIDDEN_ERROR_NA } from '@serenityapp/domain';

// urql
import { Client as UrqlClient, Provider as UrqlProvider } from 'urql';
import { createClient } from './createUrqlClient';

export interface ForbiddenErrorEvent {
  message: string;
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

const PROFILE_KEY = process.env.REACT_APP_PROFILE_KEY ?? 'dev';
const AWS_CONFIG = AwsFn.getConfigByProfileKey(PROFILE_KEY);
const [localRedirectSignIn, productionRedirectSignIn] =
  AWS_CONFIG.oauth?.redirectSignIn.split(',') ?? [];
const [localRedirectSignOut, productionRedirectSignOut] =
  AWS_CONFIG.oauth?.redirectSignOut.split(',') ?? [];
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const pubSub = new PubSub();

const showSplashElement = () => {
  const splashScreen = document.getElementById('splash');
  if (splashScreen) {
    splashScreen.style.display = 'flex';
  }
};

const hideSplashElement = () => {
  const splashScreen = document.getElementById('splash');
  if (splashScreen) {
    splashScreen.style.display = 'none';
  }
};

const getCurrentUserJWTToken = async () => {
  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();

    if (session.isValid() === false) {
      Sentry.addBreadcrumb({
        message: 'Invalid user session',
        data: {
          idTokenExpiration: idToken.getExpiration(),
        },
      });
    }

    return idToken.getJwtToken();
  } catch (error) {
    Sentry.addBreadcrumb({
      message: 'jwtToken exception',
      data: { error },
    });

    return '';
  }
};

const App = () => {
  const [{ store, stopSaga }, setStore] = useState<{
    store: EnhancedStore | null;
    stopSaga: (() => void) | null;
  }>({ store: null, stopSaga: null });

  const [analytics, setAnalytics] = useState<AnalyticsFrontendClientKinesis>();
  const [isStoreReady, setIsStoreReady] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const [urqlClient, setUrqlClient] = useState<UrqlClient>();

  useEffect(() => {
    const favicon: HTMLLinkElement | null = document.getElementById(
      'favicon',
    ) as HTMLLinkElement | null;

    if (favicon) {
      favicon.href =
        process.env.NODE_ENV === 'development'
          ? `${process.env.PUBLIC_URL}/favicon_dev.ico`
          : `${process.env.PUBLIC_URL}/favicon.ico`;
    }
  }, []);

  useEffect(() => {
    // start listening to Auth events at the very beginning,
    // so we don't miss any events, like SSO sign in.
    const authEventChannel = eventChannel((emit) => {
      const authEventListener = (data: HubCapsule) => {
        emit(data);
      };
      return Hub.listen('auth', authEventListener);
    }, buffers.sliding<HubCapsule>(30));

    const forbiddenErrorEventChannel: EventChannel<ForbiddenErrorEvent> = eventChannel((emit) => {
      const callback = (error: any) => {
        emit(error);
      };

      pubSub.subscribe(FORBIDDEN_ERROR_IP, callback);
      pubSub.subscribe(FORBIDDEN_ERROR_NA, callback);

      // Return unsubscribe function
      return () => {
        pubSub.unsubscribe(FORBIDDEN_ERROR_IP, callback);
        pubSub.unsubscribe(FORBIDDEN_ERROR_NA, callback);
      };
    });

    async function bootstrap() {
      if (AWS_CONFIG.oauth) {
        Amplify.configure({
          ...AWS_CONFIG,
          oauth: {
            ...AWS_CONFIG.oauth,
            redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
            redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
            urlOpener: (url: string) => {
              const urlToOpen = new URL(url);
              // Amplify always tries to open a logout URL when signed in using OAuth
              // We don't want that, so this is a workaround
              if (urlToOpen.pathname === '/logout') return;
              window.open(url, '_self');
            },
          },
        });
      } else {
        Amplify.configure(AWS_CONFIG);
      }

      // Get authUser
      const user = await CognitoFn.tryCurrentAuthenticatedUser(Auth);
      const isSerenityAppAdmin = user ? await CognitoFn.isUserSerenityAppAdmin(user) : false;
      const authUser = isSerenityAppAdmin ? undefined : user;

      // Configure AWS AppSync
      const appSyncConfig: Parameters<typeof createAuthLink>[0] = {
        url: AWS_CONFIG.appSync.graphqlEndpoint,
        region: AWS_CONFIG.appSync.region,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: getCurrentUserJWTToken,
        },
      };

      // The setContext function allows to modify the context of a request before sending it
      const timezoneLink: ApolloLink = setContext((_, { headers }) => {
        // Get the client's timezone
        let timezone;
        try {
          timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (error) {
          Sentry.addBreadcrumb({
            message: 'Error getting timezone',
            data: {
              error,
            },
          });
          timezone = undefined;
        }

        return {
          headers: {
            ...headers,
            'x-timezone': timezone,
          },
        };
      });

      // The Apollo error link needs to communicate with the redux store (to sign the user out and display
      // a meaningful error message in the sign in form), but the Apollo setup happens before the store is configured,
      // so we need to use an event channel to communicate between the two.
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            if (message === FORBIDDEN_ERROR_IP || message === FORBIDDEN_ERROR_NA) {
              pubSub.publish(message, { message }); // Publish event
            }
          });
        }
      });

      // TODO: (Michael) fix the generic Sentry interface compatibility with Sentry client
      const sentry = Sentry as unknown as ISentry;

      // Initialize GraphQL API interface
      ApiGraphql.init({ sentry });

      // Get apolloClient
      const apolloLink = ApolloLink.from([
        errorLink,
        createErrorMonitoringLink(sentry),
        timezoneLink,
        new RetryLink({
          delay: {
            initial: 500,
          },
        }),
        createAuthLink(appSyncConfig),
        createSubscriptionHandshakeLink(appSyncConfig),
      ]);

      const apolloClient: IApiClient = new ApolloClient({
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
          mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
        link: apolloLink,
        cache: new NullCache(),
      });

      const appSyncConfigWithApiKey: Parameters<typeof createAuthLink>[0] = {
        url: AWS_CONFIG.appSync.graphqlEndpoint,
        region: AWS_CONFIG.appSync.region,
        auth: {
          type: AUTH_TYPE.API_KEY,
          apiKey: AWS_CONFIG.appSync.apiKey,
        },
      };

      // Create the public graph client
      const publicClient = new ApolloClient({
        cache: new NullCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
          mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
        link: ApolloLink.from([
          createErrorMonitoringLink(sentry),
          new RetryLink({
            delay: {
              initial: 500,
            },
          }),
          createAuthLink(appSyncConfigWithApiKey),
          createSubscriptionHandshakeLink(appSyncConfigWithApiKey),
        ]),
      });

      let analytics = new NullAnalytics();
      try {
        analytics = new AnalyticsFrontendClientKinesis({
          region: AWS_CONFIG.project.region,
          streamName: `analytics-${PROFILE_KEY}`,
          loggingEnabled: process.env.REACT_APP_LOG_ANALYTICS === 'true',
          // This seems to be the recommended way to setup a credentials provider for Kinesis
          // (see https://github.com/awsdocs/aws-doc-sdk-examples/blob/main/javascriptv3/example_code/kinesis/src/libs/kinesisClient.js)
          // Calling Amplify's Auth.currentCredentials() crashes KinesisClient via unhandled rejection while offline
          getCredentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: AWS_CONFIG.cognito.region }),
            identityPoolId: AWS_CONFIG.cognito.identityPoolId,
          }),
        });
      } catch (analyticsInitializeError) {
        sentry.captureException(
          analyticsInitializeError instanceof Error
            ? analyticsInitializeError
            : new Error('Analytics initialization failed'),
          { extra: { analyticsInitializeError } },
        );
      }

      analytics.startSession({});

      // Track the device
      analytics.startDimension('device', {
        attributes: deviceAttributes(),
      });

      // Track the app version
      analytics.startDimension('version', {
        attributes: versionAttributes(),
      });

      // Has to happen after Amplify.configure
      const newUrqlClient = createClient(AWS_CONFIG.appSync.graphqlEndpoint, analytics);
      setUrqlClient(newUrqlClient);

      const s3Storage = await createStorageClientAndOutbox(
        { bucket: AWS_CONFIG.s3.bucket, region: AWS_CONFIG.s3.region },
        sentry,
        analytics,
        apolloClient,
      );

      const { store, stopSaga } = configureStore(
        authUser,
        AWS_CONFIG,
        sentry,
        analytics,
        apolloClient,
        publicClient,
        s3Storage.client,
        s3Storage.outbox,
        authEventChannel,
        forbiddenErrorEventChannel,
      );

      // TODO: move this logic to a common logAuthEvent saga when we do this for mobile
      // Start tracking Cognito auth events
      Hub.listen('auth', (data) => {
        const { event, message } = data?.payload ?? {};
        analytics.track({ name: 'auth/hub-event', attributes: { event, message } });
        // sign user out if their account has been disabled
        const payloadMessage = data?.payload?.data?.message;
        if (payloadMessage && payloadMessage === 'User is disabled.') {
          store.dispatch(viewerSignOut());
        }

        // Each time the user signs in, we need to create a new urql client
        if (event === 'signIn') {
          const newUrqlClient = createClient(AWS_CONFIG.appSync.graphqlEndpoint, analytics);
          setUrqlClient(newUrqlClient);
        }
      });

      setAnalytics(analytics);

      setStore({ store, stopSaga });

      setIsAppReady(true);
    }

    bootstrap();
  }, []);

  useEffect(() => {
    // as soon as store is created we want to know
    // if viewer is ready and we can access other viewer's values
    // to correctly render app
    if (store) {
      return store.subscribe(() => {
        const { ready } = getViewerState(store.getState());
        setIsStoreReady(ready);
      });
    }
  }, [store]);

  useEffect(() => stopSaga || undefined, [stopSaga]);

  // If App is not ready to be displayed we return null and make space for the splash screen to be
  // displayed.
  if (!store || !analytics || !isAppReady || !isStoreReady || !urqlClient) {
    showSplashElement();
    return null;
  }

  // After the app is ready, we hide the splash screen and the App is displayed.
  hideSplashElement();

  // TODO: (Michael) add fallback component for ErrorBoundary
  return (
    <Sentry.ErrorBoundary showDialog>
      <ReduxProvider store={store}>
        <UrqlProvider value={urqlClient}>
          <ThemeProvider theme={theme}>
            <GlobalContext.Provider value={{ analytics }}>
              <CssBaseline />
              <AppRoutes />
            </GlobalContext.Provider>
          </ThemeProvider>
        </UrqlProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
