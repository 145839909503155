import { Typography, Box, Divider } from '@mui/material';
import { FormikField, useMakeTestId } from '@serenityapp/components-react-web';
import { dividerSx } from './styles';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { LocationAddressValues } from '../schema';

const AddressFields = () => {
  const makeTestId = useMakeTestId('AddressFields');
  const { values } = useFormikContext<Partial<LocationAddressValues>>();
  const [addressError, setAddressError] = useState<string>('');

  useEffect(() => {
    const addressFields = [
      values.addressLine2,
      values.countryOrRegion,
      values.city,
      values.stateOrRegion,
      values.postalCode,
    ];
    const anyFieldFilled = addressFields.some((field) => !!field?.trim());
    const addressLine1Filled = !!values.addressLine1?.trim();

    if (anyFieldFilled && !addressLine1Filled) {
      setAddressError('Address is required if other Location Address fields are filled.');
    } else {
      setAddressError('');
    }
  }, [
    values.addressLine1,
    values.addressLine2,
    values.countryOrRegion,
    values.city,
    values.stateOrRegion,
    values.postalCode,
  ]);

  return (
    <>
      <Typography display="block" sx={sectionHeaderSx} variant="overline">
        location address
      </Typography>
      <Divider sx={dividerSx} />
      <FormikField
        dataTestId={makeTestId('CountryOrRegion')}
        label="Country/Region"
        name="countryOrRegion"
        type="text"
      />
      <FormikField
        dataTestId={makeTestId('AddressLine1')}
        helperText={addressError ? addressError : 'Street address'}
        label="Address"
        name="addressLine1"
        type="text"
        error={Boolean(addressError)}
      />
      <FormikField
        dataTestId={makeTestId('AddressLine2')}
        label="Apt, suite, building, unit, floor, etc."
        name="addressLine2"
        type="text"
      />
      <Box sx={horizontalFieldsWrapperSx}>
        <FormikField dataTestId={makeTestId('City')} label="City" name="city" type="text" />
        <FormikField
          dataTestId={makeTestId('StateOrRegion')}
          label="State"
          name="stateOrRegion"
          type="text"
        />
        <FormikField
          dataTestId={makeTestId('PostalCode')}
          label="ZIP Code"
          name="postalCode"
          type="text"
        />
      </Box>
    </>
  );
};

const horizontalFieldsWrapperSx = { display: 'flex', justifyContent: 'space-between', gap: 2 };
const sectionHeaderSx = { mt: 3 };

export default AddressFields;
