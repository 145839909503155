import { useFormikContext, FormikValues } from 'formik';
import { Typography, Divider, Alert } from '@mui/material';

import { SearchSelectorField } from '@serenityapp/components-react-web';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';
import { useCurrentUser, useLocationDevices } from '@serenityapp/client-data';

import { UnitFormValues } from '../schema';
import { dividerSx } from './styles';

const DeviceSearchSelectorField = () => {
  const { initialValues } = useFormikContext<UnitFormValues>();
  const { devices } = initialValues as FormikValues;

  const isDevicesFeatureEnabled = useIsFeatureEnabled('devices');

  const { isSerendipityUser } = useCurrentUser();

  const { devicesOptions, fetching: isFetching } = useLocationDevices(devices);

  return (
    <>
      {isDevicesFeatureEnabled ? (
        <>
          <Typography display="block" variant="overline" sx={typographySx}>
            Devices
          </Typography>
          <Divider sx={dividerSx} />
          {!isSerendipityUser && (
            <Alert severity="info" sx={alertSx}>
              Please contact Serenity Support to manage devices.
            </Alert>
          )}
          <SearchSelectorField
            items={devicesOptions}
            label="Devices"
            name="devices"
            loading={isFetching}
            disabled={!isSerendipityUser}
          />
        </>
      ) : null}
    </>
  );
};

const typographySx = { mt: 3 };

const alertSx = {
  my: 2,
  display: 'flex',
  alignItems: 'center',
};

export default DeviceSearchSelectorField;
