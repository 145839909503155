import { useState, useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { SearchSelectorField } from '@serenityapp/components-react-web';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevelFn } from '@serenityapp/core';
import { UnitFormValues } from '../schema';
import { BULLET_POINT, MULTI_PURPOSE_SERVICE_LEVEL } from '../utils';
import { Box } from '@mui/material';
import FilterDropdown from './FilterDropdown';
type UserSearchSelectorFieldProps = {
  usersOptions: SelectorItemProps[];
  loading?: boolean;
};
const options = ['All', 'Assigned', 'Unassigned'];
const UserSearchSelectorField = ({
  usersOptions: inputUsersOptions,
  loading = false,
}: UserSearchSelectorFieldProps) => {
  const { values } = useFormikContext<UnitFormValues>();
  const filterRef = useRef<HTMLButtonElement>(null);
  const [residentFilterOption, setResidentFilterOption] = useState<string>('All');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFilterDropdownOpened, setIsFilterDropdownOpened] = useState(false);
  // State to control Autocomplete open state
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const usersOptions = useMemo(() => inputUsersOptions, [inputUsersOptions]);
  const filteredUsersOptions = useMemo(() => {
    if (residentFilterOption === 'Assigned')
      return usersOptions.filter((user) => !!user.data?.unitId);
    if (residentFilterOption === 'Unassigned')
      return usersOptions.filter((user) => !user.data?.unitId);
    return usersOptions;
  }, [usersOptions, residentFilterOption]);

  // FilterDropdown event handlers
  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsFilterDropdownOpened((prev) => !prev);
  };
  const handleFilterOptionChange = (filterOption: string) => {
    setResidentFilterOption(filterOption);
    setIsFilterDropdownOpened(false);
  };
  // SearchSelectorField event handlers
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isFilterDropdownOpened) {
      setIsAutocompleteOpen(false);
      return;
    }
  };
  const handleOnBlurCapture = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === filterRef.current) {
      e.target.focus();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // This effect checks for service level mismatch between selected service level
  // for unit and service levels of users selected in the form
  useEffect(() => {
    let mismatchErrors: Record<string, string> = {};
    if (values.serviceLevel && values.serviceLevel !== MULTI_PURPOSE_SERVICE_LEVEL) {
      mismatchErrors = (values.users || []).reduce(
        (acc: Record<string, string>, user: SelectorItemProps) => {
          const userServiceLevel = user?.subtitle?.split(BULLET_POINT)[0].trim() || '';
          const errorMessage =
            "Notice: The resident's service level differs from the location unit's service level.";
          if (ServiceLevelFn.getFromString(userServiceLevel) !== values.serviceLevel) {
            acc[user.id] = acc[user.id] ? `${acc[user.id]}\n${errorMessage}` : errorMessage;
          }
          return acc;
        },
        mismatchErrors,
      );
    }
    setErrors(mismatchErrors);
  }, [values.serviceLevel, values.users, values.id]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SearchSelectorField
        customErrors={errors}
        items={filteredUsersOptions}
        label="Add residents"
        name="users"
        loading={loading}
        slotProps={{
          rightIcon: (
            <FilterDropdown
              ref={filterRef}
              label="Filter residents"
              dataTestId={'users-filter'}
              options={options}
              onClick={handleFilterIconClick}
              onChange={handleFilterOptionChange}
            />
          ),
        }}
        // props to pass to Autocomplete
        open={isAutocompleteOpen}
        onOpen={() => setIsAutocompleteOpen(true)}
        onClose={() => setIsAutocompleteOpen(false)}
        // props to pass to TextField
        inputProps={{
          onBlur: handleOnBlur,
          onBlurCapture: handleOnBlurCapture,
        }}
      />
    </Box>
  );
};

export default UserSearchSelectorField;
