import { Schema } from '@serenityapp/domain';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { Unit } from '@serenityapp/api-graphql';
import { resolveCacheField, updateAmountOfUsersInLocation } from './utils';

/**
 * Handles unit update mutation result in urql cache
 * catches the case when users are assigned to the unit
 */
export default function unitUpdate(
  result: Unit.Api.UnitUpdateMutationResult,
  _args: Schema.Unit.Update.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const updatedUnitOutput = result?.result;

  if (info.error || !updatedUnitOutput?.success) {
    return;
  }

  const { organizationId, users, id } = updatedUnitOutput.item;

  if (!users || users.edges.length === 0) {
    return;
  }

  const previousUsers = resolveCacheField<{ edges: { user: { id: string } }[] }>(
    cache,
    { __typename: 'Unit', id },
    'users',
  );

  const previousUserIds = new Set(previousUsers?.edges?.map((edge) => edge.user.id) || []);
  const updatedUserIds = new Set(users?.edges?.map((edge) => edge.user.id) || []);

  // Compare the sets to see if the number of users changed
  if (
    previousUserIds.size === updatedUserIds.size &&
    [...previousUserIds].every((id) => updatedUserIds.has(id))
  ) {
    return;
  }

  updateAmountOfUsersInLocation(cache, organizationId, updatedUserIds, id);
}
