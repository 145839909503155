import { Box, Grid } from '@mui/material';

import { AdministrationHeader, useMakeTestId, View } from '@serenityapp/components-react-web';
import { StringFn } from '@serenityapp/core';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';

import { cardsWrapperSx } from './adminPageStyles';
import DashboardCard, { DashboardCardProps } from './DashboardCard';

import appsCardImage from '../../../assets/AdminDashboard/Apps.svg';
import channelsCardImage from '../../../assets/AdminDashboard/Channels.svg';
import filtersCardImage from '../../../assets/AdminDashboard/Filters.svg';
import locationsCardImage from '../../../assets/AdminDashboard/Locations.svg';
import devicesCardImage from '../../../assets/AdminDashboard/Devices.svg';
import generalSettingsCardImage from '../../../assets/AdminDashboard/GeneralSettings.svg';
import rolesAndPermissionsCardImage from '../../../assets/AdminDashboard/RolesAndPermissions.svg';
import usersCardImage from '../../../assets/AdminDashboard/Users.svg';

const AdminPage = () => {
  const makeTestId = useMakeTestId('AdminPage');

  const isA4SLFeatureEnabled = useIsFeatureEnabled('a4sl');
  const isCalendarFeatureEnabled = useIsFeatureEnabled('calendar');
  const isCheckInFeatureEnabled = useIsFeatureEnabled('check-in');
  const isCloudStorageFeatureEnabled = useIsFeatureEnabled('cloudStorageApps');
  const isLocationsFeatureEnabled = useIsFeatureEnabled('locations');
  const isDevicesFeatureEnabled = useIsFeatureEnabled('devices');

  const shouldDisplayApps =
    isA4SLFeatureEnabled ||
    isCheckInFeatureEnabled ||
    isCalendarFeatureEnabled ||
    isCloudStorageFeatureEnabled ||
    isLocationsFeatureEnabled ||
    isDevicesFeatureEnabled;

  const shouldDisplayGeneralSettings = useIsFeatureEnabled('generalSettings');

  const isRoomsFeatureEnabled = useIsFeatureEnabled('rooms');
  const shouldDisplayRooms = isRoomsFeatureEnabled;

  const dashboardCardItems: DashboardCardProps[] = [
    {
      cardMedia: usersCardImage,
      title: 'Users',
      description: 'Add or manage users',
      to: 'users',
    },
    {
      cardMedia: channelsCardImage,
      title: 'Channels',
      description: 'Add or manage channels',
      to: 'conversations',
    },
    {
      cardMedia: filtersCardImage,
      title: 'Filters',
      description: 'Add and manage filters to better organize information',
      to: 'filters',
    },
    {
      cardMedia: rolesAndPermissionsCardImage,
      title: 'Groups',
      description: 'Add or manage user roles and permissions',
      to: 'groups',
    },
  ];

  if (isLocationsFeatureEnabled) {
    dashboardCardItems.push({
      cardMedia: locationsCardImage,
      title: 'Locations',
      description: 'Add or manage locations inventory',
      to: 'locations',
    });
  }

  if (isDevicesFeatureEnabled) {
    dashboardCardItems.push({
      cardMedia: devicesCardImage,
      title: 'Devices',
      description: 'Manage devices inventory',
      to: 'devices',
    });
  }

  if (shouldDisplayRooms) {
    dashboardCardItems.push({
      // TODO: add rooms card image
      cardMedia: filtersCardImage,
      title: 'Rooms',
      description: 'Add or manage rooms',
      to: 'rooms',
    });
  }

  if (shouldDisplayGeneralSettings) {
    dashboardCardItems.push({
      cardMedia: generalSettingsCardImage,
      title: 'General Settings',
      description: 'Update general company information and settings',
      to: 'settings',
    });
  }

  if (shouldDisplayApps) {
    dashboardCardItems.push({
      cardMedia: appsCardImage,
      title: 'Apps',
      description: 'Enhance your workflow with other apps',
      to: 'apps',
    });
  }

  return (
    <View dataTestId={makeTestId('')}>
      <View.Header>
        <AdministrationHeader title="Admin" />
      </View.Header>
      <View.Content>
        <Box sx={cardsWrapperSx}>
          <Grid container spacing={3}>
            {dashboardCardItems.map((item) => {
              const key = StringFn.generateKey(item.title);
              return (
                <Grid key={key} container item md={4} sm={6} xl={3} xs={12}>
                  <DashboardCard
                    cardMedia={item.cardMedia}
                    dataTestId={makeTestId(key)}
                    description={item.description}
                    title={item.title}
                    to={item.to}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </View.Content>
    </View>
  );
};

export default AdminPage;
