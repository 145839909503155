import { Schema } from '@serenityapp/domain';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { addLocation } from './utils';
import { Unit } from '@serenityapp/api-graphql';
import { updateAmountOfUsersInLocation } from './utils';

export default function unitCreate(
  result: Unit.Api.UnitCreateMutationResult,
  _args: Schema.Unit.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdUnitOutput = result?.result;

  if (info.error || !createdUnitOutput || !createdUnitOutput.success) {
    return;
  }

  const { organizationId, displayName, id, name, edited, users } = createdUnitOutput.item;

  const location: Schema.Location.ItemWithTypename = {
    id,
    displayName: displayName ?? 'Unit',
    name,
    edited,
    kind: 'Unit',
    __typename: 'Unit',
  };

  addLocation(location, organizationId, cache);

  if (users) {
    const updatedUserIds = new Set(users?.edges?.map((edge) => edge.user.id) || []);

    updateAmountOfUsersInLocation(cache, organizationId, updatedUserIds, id);
  }
}
