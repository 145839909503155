import React, { forwardRef, useState, useId, useMemo, useCallback } from 'react';
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DoneIcon from '@mui/icons-material/Done';
import { useMakeTestId } from '@serenityapp/components-react-web';

export type Props = {
  options: string[];
  dataTestId: string;
  onChange: (option: string) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  label?: string;
  initialOption?: string;
};

const FilterDropdown = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label = 'Filter',
      options,
      dataTestId,
      onChange,
      onClick,
      initialOption = options[0] ?? 'All',
    },
    ref,
  ) => {
    const makeTestId = useMakeTestId('FilterDropdown', dataTestId);
    const mainTestId = makeTestId('');
    const menuId = useId();

    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
    const [selectedOption, setSelectedOption] = useState<string>(initialOption);

    const handleFilterClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu((elem) => (elem === null ? event.currentTarget : null));
        if (onClick) onClick(event);
      },
      [onClick],
    );

    const handleSelectOption = useCallback(
      (option: string) => {
        setSelectedOption(option);
        onChange(option);
        setAnchorElMenu(null);
      },
      [onChange],
    );

    const handleKeyDownOpenDropdown = useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          handleFilterClick(event as unknown as React.MouseEvent<HTMLElement>);
        }
      },
      [handleFilterClick],
    );

    const handleMenuItemKeyDown = useCallback(
      (event: React.KeyboardEvent, option: string) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          handleSelectOption(option);
        }
      },
      [handleSelectOption],
    );

    const menuItems = useMemo(
      () =>
        options.map((option) => (
          <MenuItem
            sx={{
              menuItemWrapperSx,
              ...(selectedOption === option && menuItemWrapperSelectedSx),
            }}
            key={option}
            onClick={() => handleSelectOption(option)}
            onKeyDown={(e) => handleMenuItemKeyDown(e, option)}
            role="menuitemradio"
            aria-checked={selectedOption === option}
            data-testid={makeTestId(`FilterOption-${option}`)}
          >
            <Box sx={filterOptionWrapperSx}>
              <Typography sx={filterOptionLabelSx}>{option}</Typography>
              <DoneIcon
                data-testid={makeTestId(`FilterOption-${option}-checkmark`)}
                sx={{
                  filterOptionCheckmarkSx,
                  ...(selectedOption !== option && filterOptionCheckmarkHiddenSx),
                }}
                aria-hidden="true"
              />
            </Box>
          </MenuItem>
        )),
      [options, selectedOption, makeTestId, handleSelectOption, handleMenuItemKeyDown],
    );

    return (
      <Box data-testid={mainTestId} sx={filterDropdownWrapperSx}>
        <Tooltip title={label}>
          <IconButton
            sx={iconButtonSx}
            ref={ref}
            aria-label={label}
            aria-haspopup="menu"
            aria-expanded={Boolean(anchorElMenu)}
            aria-controls={menuId}
            data-testid={makeTestId('FilterButton')}
            size="small"
            onClick={handleFilterClick}
            onKeyDown={handleKeyDownOpenDropdown}
          >
            <FilterAltIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Menu
          id={menuId}
          sx={dropdownMenuSx}
          anchorEl={anchorElMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElMenu)}
          onClose={handleFilterClick}
          data-testid={makeTestId('FilterOptionsMenu')}
        >
          <Typography sx={filterMenuHeadingSx} variant="overline">
            Filter search results
          </Typography>
          <Box sx={dividerWrapperSx}>
            <Divider />
          </Box>
          {menuItems}
        </Menu>
      </Box>
    );
  },
);

const filterDropdownWrapperSx = { display: 'inline-flex', flexGrow: 0 };

const iconButtonSx = {
  mr: 1,
};

const dividerWrapperSx = {
  my: 1,
};

// MUI dropdowns has z-index 1300, filter dropdown should be above it
const dropdownMenuSx = { mt: '3rem', zIndex: 1301 };

const menuItemWrapperSx = {
  width: '220px',
};

const menuItemWrapperSelectedSx = {
  bgcolor: 'primary.outlinedHoverBackground',
};

const filterMenuHeadingSx = {
  display: 'inline-block',
  width: '100%',
  px: 2,
  py: 0.25,
};

const filterOptionLabelSx = {
  textAlign: 'center',
};

const filterOptionCheckmarkSx = {
  color: 'primary.main',
};

const filterOptionCheckmarkHiddenSx = {
  visibility: 'hidden',
};

const filterOptionWrapperSx = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 2,
  py: 0.25,
};

export default FilterDropdown;
