import { SyntheticEvent, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Divider, Typography } from '@mui/material';

import {
  useBuildings,
  buildingFloorsToSelectorItemProps,
  useLocationGroups,
  useResidentUsers,
} from '@serenityapp/client-data';
import { FormikField, SelectField, useMakeTestId } from '@serenityapp/components-react-web';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevel, ServiceLevelFn } from '@serenityapp/core';

import { transformServiceLevelsToSelectorItemProps } from '../../../common/utils';
import { BULLET_POINT, MULTI_PURPOSE_SERVICE_LEVEL } from '../utils';
import { UnitFormValues } from '../schema';

import AddressFields from './AddressFields';
import AutocompleteField from './AutocompleteField';
import ServiceLevelMismatchDialog from './ServiceLevelMismatchDialog';
import UserSearchSelectorField from './UserSearchSelectorField';
import { dividerSx } from './styles';
import DeviceSearchSelectorField from './DeviceSearchSelectorField';
import LocationAssignmentConflictDialog from './LocationAssignmentConflictDialog';

const serviceLevels: SelectorItemProps[] = transformServiceLevelsToSelectorItemProps(
  Object.values(ServiceLevel),
).concat([{ id: MULTI_PURPOSE_SERVICE_LEVEL, label: 'Multi-purpose' }]);

type UnitFieldsProps = {
  isEditMode: boolean;
};

const UnitFields = ({ isEditMode = false }: UnitFieldsProps) => {
  const { setFieldValue, initialValues, values, dirty } = useFormikContext<UnitFormValues>();

  // States to track if there's an "Assigned to Another Unit" error
  // or "Service Level Mismatch" errors
  const [locationAssignmentConflictError, setLocationAssignmentConflictError] = useState(false);
  const [serviceLevelMismatchError, setServiceLevelMismatchError] = useState(false);

  const [isAssignedDialogResolved, setIsAssignedDialogResolved] = useState(true);

  const { floorId, buildingId, pccRecordStamp, locationGroupId } = initialValues as FormikValues;

  const makeTestId = useMakeTestId('UnitFields');

  const [selectedLocationGroupId, setSelectedLocationGroupId] = useState(locationGroupId);
  const [selectedBuildingId, setSelectedBuildingId] = useState(buildingId);
  const [selectedFloorId, setSelectedFloorId] = useState(floorId);

  const [mismatchData, setMismatchData] = useState<
    | {
        users: SelectorItemProps[];
      }
    | undefined
  >();

  const {
    locationGroupOptions,
    locationGroupBuildingsSelectItems,
    fetching: locationGroupsFetching,
  } = useLocationGroups(selectedLocationGroupId);

  useEffect(() => {
    if (initialValues) {
      setSelectedLocationGroupId(locationGroupId);
      setSelectedBuildingId(buildingId);
      setSelectedFloorId(floorId);
    }
  }, [initialValues, locationGroupId, buildingId, floorId]);

  // Buildings logic
  const {
    fetching: buildingsFetching,
    buildingOptions,
    selectedBuilding,
  } = useBuildings(selectedBuildingId);

  // Floors logic
  const floorsOptions = buildingFloorsToSelectorItemProps(selectedBuilding);

  // Fetch users
  const { fetching: usersFetching, usersOptions } = useResidentUsers();

  const selectedBuildingItem = buildingOptions.find(
    (item: SelectorItemProps) => item.id === selectedBuildingId,
  );

  const selectedFloorItem = floorsOptions.find(
    (item: SelectorItemProps) => item.id === selectedFloorId,
  );

  const selectedLocationGroupItem: SelectorItemProps | undefined = locationGroupOptions.find(
    (item: SelectorItemProps) => item.id === selectedLocationGroupId,
  );

  const clearFloorInput = () => {
    setSelectedFloorId(undefined);
    setFieldValue('floor', null);
    setFieldValue('floorId', null);
  };

  const clearBuildingInput = () => {
    setFieldValue('building', null);
    setFieldValue('buildingId', null);
    setSelectedBuildingId(undefined);
  };

  const clearLocationGroupInput = () => {
    setFieldValue('locationGroup', null);
    setFieldValue('locationGroupId', null);
    setSelectedLocationGroupId(undefined);
  };

  const handleLocationGroupChange = (
    event: SyntheticEvent,
    value: SelectorItemProps,
    reason: string,
  ) => {
    if (reason === 'clear') {
      clearFloorInput();
      clearBuildingInput();
      clearLocationGroupInput();

      return;
    }
    setFieldValue('locationGroup', value);
    setFieldValue('locationGroupId', value?.id);
    setSelectedLocationGroupId(value?.id || null);

    // clear up building and floor inputs
    clearBuildingInput();
    clearFloorInput();
  };

  const handleBuildingChange = (
    event: SyntheticEvent,
    value: SelectorItemProps,
    reason: string,
  ) => {
    if (reason === 'clear') {
      clearFloorInput();
      clearBuildingInput();
      return;
    }

    setFieldValue('building', value);
    setFieldValue('buildingId', value?.id);
    setSelectedBuildingId(value?.id || null);
    clearFloorInput();
  };

  const handleFloorChange = (event: SyntheticEvent, value: SelectorItemProps, reason: string) => {
    if (reason === 'clear') {
      clearFloorInput();
      return;
    }

    setFieldValue('floor', value);
    setFieldValue('floorId', value?.id);
    setSelectedFloorId(value?.id || null);
  };

  useEffect(() => {
    if (!values?.users) return;

    // Check if any user is assigned to another unit
    const hasAssignedElsewhere = values.users.some((user: SelectorItemProps) => {
      return user.data?.unitId && user.data?.unitId !== values.id && !user?.data?.resolved;
    });

    if (hasAssignedElsewhere) {
      setIsAssignedDialogResolved(false);
    }

    // Check if there's a service level mismatch
    const hasServiceLevelMismatch = values.users.some((user: SelectorItemProps) => {
      const userServiceLevel = user?.subtitle?.split(BULLET_POINT)[0].trim();

      return ServiceLevelFn.getFromString(userServiceLevel) !== values.serviceLevel;
    });

    setLocationAssignmentConflictError(hasAssignedElsewhere);
    setServiceLevelMismatchError(hasServiceLevelMismatch);
  }, [values.users, values.id, values.serviceLevel]);

  useEffect(() => {
    // We do not want to validate residents if the form was not touched yet
    if (!dirty) return;

    const residentsAssignedToAnotherUnit = (values.users as SelectorItemProps[]).filter(
      (user) => user?.data?.unitId && user?.data?.unitId !== values.id && !user?.data?.resolved,
    );

    if (residentsAssignedToAnotherUnit.length > 0) {
      setMismatchData({
        users: residentsAssignedToAnotherUnit,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.users]);

  return (
    <>
      {isEditMode && pccRecordStamp && (
        <FormikField
          dataTestId={makeTestId('PCCRecordStamp')}
          disabled
          label="PointClickCare record type"
          name="pccRecordStamp"
          type="text"
        />
      )}
      <FormikField
        dataTestId={makeTestId('DisplayName')}
        helperText="Overwrite default location type name"
        label="Display name"
        name="displayName"
        type="text"
      />
      <SelectField
        dataTestId={makeTestId('ServiceLevel')}
        items={serviceLevels}
        label="Service level"
        name="serviceLevel"
      />
      <AutocompleteField
        dataTestId={makeTestId('LocationGroup')}
        items={locationGroupOptions}
        label="Location Group"
        name="locationGroup"
        value={selectedLocationGroupItem || null}
        onChange={handleLocationGroupChange}
        loading={locationGroupsFetching}
      />
      <AutocompleteField
        dataTestId={makeTestId('Building')}
        items={
          locationGroupBuildingsSelectItems?.length === 0 && !selectedLocationGroupItem
            ? buildingOptions
            : locationGroupBuildingsSelectItems
        }
        label="Building"
        name="building"
        value={selectedBuildingItem || null}
        onChange={handleBuildingChange}
        loading={buildingsFetching}
      />
      <AutocompleteField
        dataTestId={makeTestId('Floor')}
        items={floorsOptions}
        label="Floor"
        name="floor"
        value={selectedFloorItem || null}
        onChange={handleFloorChange}
      />
      <Typography display="block" variant="overline">
        residents
      </Typography>
      <Divider sx={dividerSx} />
      <UserSearchSelectorField loading={usersFetching} usersOptions={usersOptions} />
      <DeviceSearchSelectorField />
      <AddressFields />
      {locationAssignmentConflictError &&
        mismatchData?.users.map((user) => (
          <LocationAssignmentConflictDialog
            key={user.id}
            conflictedUser={user}
            resolveAssignmentConflict={(data) => {
              setMismatchData(data);
            }}
            setIsDialogResolved={(resolved) => {
              setIsAssignedDialogResolved(resolved);
            }}
          />
        ))}
      {/* Show "Service Level Mismatch" Dialog Only After "Assigned to Another Unit" is Resolved */}
      {serviceLevelMismatchError && isAssignedDialogResolved && <ServiceLevelMismatchDialog />}
    </>
  );
};

export default UnitFields;
