import { useSelector } from 'react-redux';
import { UserProps } from '@serenityapp/domain';
import { getCurrentUserFromViewerState, RootState } from '@serenityapp/redux-store';
import * as R from 'ramda';

export default function useCurrentUser(): UserProps | undefined {
  // TODO (viktor): currently fails if there are no conversations for the current user
  return useSelector<RootState, ReturnType<typeof getCurrentUserFromViewerState>>(
    getCurrentUserFromViewerState,
    R.equals,
  ).user;
}
