import { Add, Upload } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowProps,
} from '@mui/x-data-grid-pro';
import { memo, useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUsers } from '@serenityapp/client-data';
import {
  AdministrationHeader,
  AvatarDataGridCell,
  useMakeTestId,
  UserStatusTableCell,
  View,
} from '@serenityapp/components-react-web';
import { StringFn } from '@serenityapp/core';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';

import AdminGridData from '../components/AdminDataGrid';
import CustomGridRow from '../components/CustomGridRow';
import { useAdminBreadcrumbs } from '../hooks';
import {
  ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS,
  DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE,
  gridNumericAwareStringColComparator,
} from '../utils';
import { ActionsCell, ImportUsersDialog, QuickSearchToolbarUsers } from './components';

const NOT_AVAILABLE_STATUS = 'NOT_AVAILABLE';

// Memoize AdminGridData to avoid unnecessary re-renders happening due to useIsFeatureEnabled hook
const MemoizedAdminGridData = memo(AdminGridData, (prevProps, nextProps) => {
  return (
    prevProps.columns === nextProps.columns &&
    prevProps.rows === nextProps.rows &&
    prevProps.loading === nextProps.loading &&
    JSON.stringify(prevProps.initialState) === JSON.stringify(nextProps.initialState)
  );
});

const UsersPage = () => {
  const title = 'User Management';
  const makeTestId = useMakeTestId('UsersPage');
  const mainTestId = makeTestId('');

  const navigate = useNavigate();

  const [importUsersDialog, setImportUsersDialog] = useState(false);

  const isRemoveUsernamesFeatureEnabled = useIsFeatureEnabled('removeUsernames');
  const isUsersImportFeatureEnabled = useIsFeatureEnabled('usersImport');
  const isLocationFeatureEnabled = useIsFeatureEnabled('locations');

  const { users, fetching } = useUsers();

  const actionsCellRenderer = useCallback(
    (params: GridRenderCellParams) => (
      <ActionsCell params={params} dataTestId={makeTestId('actions')} />
    ),
    [makeTestId],
  );

  const statusCellRenderer = useCallback(
    (params: GridRenderCellParams) => (
      <UserStatusTableCell
        status={params.row.status || NOT_AVAILABLE_STATUS}
        dataTestId={makeTestId('status')}
      />
    ),
    [makeTestId],
  );

  const locatorCellRenderer = useCallback(
    (params: GridRenderCellParams) => {
      if (!params || !params.row.locator) return '-';

      const { unitName, unitTypeDisplayName, buildingName, floorName, locationGroupName } =
        params.row.locator || {};
      const unitNameWithType = `${unitTypeDisplayName} ${unitName}`;
      const fullLocationPath = [locationGroupName, buildingName, floorName, unitNameWithType]
        .filter(Boolean)
        .join('/');

      return (
        <Tooltip
          key={`locator-cell-${params.row.id}`}
          placement="bottom-start"
          title={fullLocationPath}
          data-testid={makeTestId('locator')}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -30],
                  },
                },
              ],
            },
          }}
        >
          <Box>{`${unitTypeDisplayName} ${unitName}`}</Box>
        </Tooltip>
      );
    },
    [makeTestId],
  );

  const avatarCellRenderer = useCallback(
    (params: GridRenderCellParams) => {
      const initials =
        isRemoveUsernamesFeatureEnabled && params.row.initials
          ? params.row.initials
          : StringFn.initials(params.row.fullName || params.row.name);

      const name = isRemoveUsernamesFeatureEnabled
        ? params.row.fullName || params.row.name
        : params.row.name || params.row.fullName || '';

      return (
        <AvatarDataGridCell
          key={`avatar-cell-${params.row.id}`}
          dataTestId={makeTestId('')}
          initials={initials}
          name={name}
        />
      );
    },
    [makeTestId, isRemoveUsernamesFeatureEnabled],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: isRemoveUsernamesFeatureEnabled ? 'fullName' : 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: avatarCellRenderer,
        sortable: true,
        filterable: false,
        type: 'string',
        sortComparator: gridNumericAwareStringColComparator,
      },
      {
        field: 'subtype',
        headerName: 'Type',
        flex: 1,
        sortable: true,
        filterable: false,
        type: 'string',
      },
      ...(isLocationFeatureEnabled
        ? [
            {
              field: 'locator',
              headerName: 'Location',
              flex: 1,
              renderCell: locatorCellRenderer,
              sortable: true,
              filterable: false,
            },
          ]
        : []),
      {
        field: 'status',
        headerName: 'Account status',
        flex: 1,
        renderCell: statusCellRenderer,
        sortable: true,
        filterable: false,
        type: 'string',
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        hide: 'smDown',
        sortable: true,
        filterable: false,
        type: 'string',
        sortComparator: gridNumericAwareStringColComparator,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        renderCell: actionsCellRenderer,
        sortable: false,
        filterable: false,
      },
    ],
    [
      isRemoveUsernamesFeatureEnabled,
      isLocationFeatureEnabled,
      avatarCellRenderer,
      locatorCellRenderer,
      statusCellRenderer,
      actionsCellRenderer,
    ],
  );

  const goToResourceDetails = (params: GridRowParams) => {
    const { id } = params;
    navigate(id.toString());
  };
  const goToResourceCreate = () => navigate('create');

  const crumbs = useAdminBreadcrumbs(title);

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;user
      </Box>
    </Button>
  );

  const handleImportUsersButton = () => setImportUsersDialog(true);
  const importUsersButton = (
    <Button
      key="import-users-button"
      data-testid={makeTestId('import-users')}
      startIcon={<Upload />}
      variant="outlined"
      onClick={handleImportUsersButton}
    >
      Import
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;users
      </Box>
    </Button>
  );

  return (
    <>
      <View dataTestId={mainTestId}>
        <View.Header>
          <AdministrationHeader
            actions={[isUsersImportFeatureEnabled && importUsersButton, addButton]}
            crumbs={crumbs}
            title={title}
          />
        </View.Header>
        <View.Content>
          <ImportUsersDialog
            isOpen={importUsersDialog}
            onClose={() => setImportUsersDialog(false)}
          />
          <MemoizedAdminGridData
            disableColumnSelector
            disableRowSelectionOnClick
            ignoreDiacritics
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: 'subtype', sort: 'asc' }],
              },
              pagination: { paginationModel: { pageSize: DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE } },
            }}
            loading={fetching && !users.length}
            pageSizeOptions={ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS}
            onRowClick={goToResourceDetails}
            pagination
            columns={columns}
            rows={users}
            slots={{
              toolbar: QuickSearchToolbarUsers,
              row: (props: GridRowProps) => <CustomGridRow {...props} dataTestId={mainTestId} />,
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'skeleton',
              },
            }}
          />
          <Outlet />
        </View.Content>
      </View>
    </>
  );
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default UsersPage;
