import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';

import { SelectorItemProps } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';

import { UnitFormValues } from '../schema';

const ASSIGN_RESIDENT_TO_CURRENT_UNIT = 'assignResidentToCurrentUnit';
const KEEP_RESIDENT_IN_PREVIOUS_UNIT = 'keepResidentInPreviousUnit';

type LocationAssignmentConflictDialogProps = {
  setIsDialogResolved: (isResolved: boolean) => void;
  resolveAssignmentConflict: (data: any) => void;
  conflictedUser: SelectorItemProps;
};

/** This dialog gets open when residents are assigned to another unit.
 * @returns a dialog component
 */
const LocationAssignmentConflictDialog = ({
  setIsDialogResolved,
  conflictedUser,
  resolveAssignmentConflict,
}: LocationAssignmentConflictDialogProps) => {
  const makeTestId = useMakeTestId('LocationAssignmentConflictDialog');
  const mainTestId = makeTestId('');
  const { values, setFieldValue } = useFormikContext<UnitFormValues>();

  const currentUnitName = values?.locationName;

  const [currentRadioGroupValue, setCurrentRadioGroupValue] = useState<string>(
    KEEP_RESIDENT_IN_PREVIOUS_UNIT,
  );

  const closeDialog = () => {
    const result = values?.users?.filter(
      (user: SelectorItemProps) => user.id !== conflictedUser.id,
    );
    setFieldValue('users', result);
    resolveAssignmentConflict(undefined);
    setIsDialogResolved(true);
    setCurrentRadioGroupValue(KEEP_RESIDENT_IN_PREVIOUS_UNIT);
  };

  const onChangeRadioGroupValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentRadioGroupValue(event.target.value);
  };

  const assignResidentToCurrentUnit = () => {
    const withResolved = values?.users?.map((user: SelectorItemProps) => {
      if (user.id === conflictedUser.id) {
        return {
          ...user,
          data: {
            ...user.data,
            resolved: true,
          },
        };
      }
      return user;
    });
    setFieldValue('users', withResolved);
    setIsDialogResolved(true);
    resolveAssignmentConflict(undefined);
    setIsDialogResolved(true);
    setCurrentRadioGroupValue(KEEP_RESIDENT_IN_PREVIOUS_UNIT);
  };

  const keepResidentInPreviousUnit = () => {
    const result = values?.users?.filter(
      (user: SelectorItemProps) => user.id !== conflictedUser.id,
    );
    setFieldValue('users', result);
    setIsDialogResolved(true);
    resolveAssignmentConflict(undefined);
    setIsDialogResolved(true);
    setCurrentRadioGroupValue(KEEP_RESIDENT_IN_PREVIOUS_UNIT);
  };

  const handleUpdate = () => {
    switch (currentRadioGroupValue) {
      case ASSIGN_RESIDENT_TO_CURRENT_UNIT:
        assignResidentToCurrentUnit();
        break;
      case KEEP_RESIDENT_IN_PREVIOUS_UNIT:
        keepResidentInPreviousUnit();
        break;
      default:
        break;
    }
  };

  return (
    <Dialog data-testid={mainTestId} open={!!conflictedUser}>
      <DialogTitle>Location Assignment Conflict Detected</DialogTitle>
      <DialogContent>
        <Box>
          <Typography component="span" sx={mediumTypographySx}>
            {conflictedUser?.label}
          </Typography>{' '}
          is already assigned to{' '}
          <Typography component="span" sx={mediumTypographySx}>
            {conflictedUser?.data?.unitName}
          </Typography>
          . To proceed select one of the following options:
        </Box>
        <RadioGroup
          data-testid={makeTestId('radio-group')}
          defaultValue={KEEP_RESIDENT_IN_PREVIOUS_UNIT}
          value={currentRadioGroupValue}
          onChange={onChangeRadioGroupValue}
          sx={{ mt: 2 }}
        >
          <FormControlLabel
            control={
              <Radio color="primary" data-testid={makeTestId('keep-resident-in-previous-unit')} />
            }
            label={`Keep resident in ${conflictedUser?.data?.unitName}`}
            value={KEEP_RESIDENT_IN_PREVIOUS_UNIT}
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                data-testid={makeTestId('assign-resident-to-current-unit')}
              />
            }
            label={`Assign resident to ${conflictedUser?.data?.displayName || 'Unit'} ${currentUnitName}`}
            value={ASSIGN_RESIDENT_TO_CURRENT_UNIT}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" data-testid={makeTestId('cancel')} onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          data-testid={makeTestId('confirm')}
          variant="contained"
          onClick={handleUpdate}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const mediumTypographySx = {
  fontWeight: 'medium',
};

export default LocationAssignmentConflictDialog;
