import { Box, Button, ListItem, ListItemText, Typography } from '@mui/material';
import { UserProps } from '@serenityapp/domain';

type LocationInfoProps = {
  userLocator?: UserProps['locator'];
};

export const LocationInfo = ({ userLocator }: LocationInfoProps) => {
  const {
    locationGroupName,
    buildingName,
    floorName,
    unitName,
    unitId,
    unitTypeDisplayName,
    buildingTypeDisplayName,
  } = userLocator || {};

  if (!userLocator)
    return (
      <Box sx={emptyStateBoxSx}>
        <Box>
          <ListItem disableGutters>
            <ListItemText primary="Location" secondary="Not assigned" />
          </ListItem>
        </Box>
        <Button
          sx={viewButtonSx}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          href="/admin/locations"
          aria-label="View locations"
        >
          View Locations
        </Button>
      </Box>
    );

  return (
    <>
      {!!locationGroupName && (
        <Box sx={locationBoxSx}>
          <Typography>{locationGroupName}</Typography>
          <Typography variant="caption" sx={captionSx}>
            Location Group
          </Typography>
        </Box>
      )}

      {!!buildingName && (
        <Box sx={locationBoxSx}>
          <Typography>{buildingName}</Typography>
          <Typography variant="caption" sx={captionSx}>
            {buildingTypeDisplayName || 'Building'}
          </Typography>
        </Box>
      )}

      {!!floorName && (
        <Box sx={locationBoxSx}>
          <Typography>{floorName}</Typography>
          <Typography variant="caption" sx={captionSx}>
            Floor
          </Typography>
        </Box>
      )}

      {!!unitName && (
        <Box sx={locationBoxWithButtonSx}>
          <Box sx={locationBoxSx}>
            <Typography>{unitName}</Typography>
            <Typography variant="caption" sx={captionSx}>
              {unitTypeDisplayName || 'Unit'}
            </Typography>
          </Box>
          <Button
            component="a"
            href={`/admin/locations/${unitId}`}
            variant="outlined"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`View details for unit ${unitName}`}
            sx={viewButtonSx}
          >
            View Location
          </Button>
        </Box>
      )}
    </>
  );
};

const locationBoxSx = {
  display: 'flex',
  flexDirection: 'column',
  mb: 2,
};

const locationBoxWithButtonSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 2,
};

const viewButtonSx = {
  color: 'text.primary',
  borderColor: 'neutral.outlinedRestingBorder',
};

const captionSx = {
  color: 'text.secondary',
};

const emptyStateBoxSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  pb: 4,
};

export default LocationInfo;
