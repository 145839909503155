import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'urql';

import { ClientApi } from '@serenityapp/api-client-graph';
import { useCurrentUser, useUserDetails } from '@serenityapp/client-data';
import { UserCreateEditFormValuesLegacy } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { EnumFn, PhoneNumberFn, ServiceLevel } from '@serenityapp/core';
import { IdFn } from '@serenityapp/core-id';
import {
  ConversationKind,
  ConversationSubjectKind,
  RouteParamId,
  UserErrorMessages,
  UserType,
  UserTypeFn,
} from '@serenityapp/domain';
import { snackAdd } from '@serenityapp/redux-store';

import { UserCreateEditFormLegacy } from './components';

const UserEditDrawerLegacy = () => {
  const makeTestId = useMakeTestId('UserEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams<RouteParamId>() as RouteParamId;
  const currentUser = useCurrentUser();
  const orgId = currentUser?.user?.orgId;

  const [isUpdating, setIsUpdating] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [, updateUser] = useMutation<
    ClientApi.User.Api.Update.MutationResult,
    ClientApi.User.Api.Update.Variables
  >(ClientApi.User.Api.Update.Mutation);

  const { user, generalChannels, residentChannels, stale, fetching } = useUserDetails(id);

  const initialValues = {
    createAccount: false,
    department: user?.department || '',
    description: user?.description,
    email: user?.email || '',
    fullName: user?.fullName,
    generalChannels,
    jobTitle: user?.jobTitle,
    userName: user?.name,
    phoneNumber: PhoneNumberFn.getNationalNumber(user?.phoneNumber) || '',
    residentChannels,
    userType: user?.subsubtype ? user?.subsubtype : user?.subtype,
    workerCategory: user?.workerCategory,
    serviceLevel:
      user?.serviceLevel &&
      user?.serviceLevel !== ServiceLevel.UNKNOWN &&
      user?.serviceLevel !== ServiceLevel.NONE
        ? user.serviceLevel
        : '',
    checkIn: !user?.checkInOptOut,
    room: user?.room?.id || undefined,
    locator: {
      unitId: user?.locator?.unitId,
      unitName: user?.locator?.unitName || '',
      unitTypeDisplayName: user?.locator?.unitTypeDisplayName || 'Unit',
      buildingName: user?.locator?.buildingName || '',
      buildingTypeDisplayName: user?.locator?.buildingTypeDisplayName || 'Building',
      floorName: user?.locator?.floorName || '',
      locationGroupName: user?.locator?.locationGroupName || '',
      serviceLevels: user?.locator?.serviceLevels,
    },
  };

  const hasUnitAssigned = user?.locator;
  const unitServiceLevels = user?.locator?.serviceLevels || ServiceLevel.NONE;
  const userServiceLevel = user?.serviceLevel || ServiceLevel.NONE;

  // Check only if unit is assigned
  const hasServiceLevelMismatch =
    hasUnitAssigned && !unitServiceLevels.includes(userServiceLevel);

  const initialErrors = {
    serviceLevel: hasServiceLevelMismatch
      ? "The resident's service level does not match the service level of the unit"
      : '',
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = ({
    createAccount,
    department,
    description,
    email,
    familyAndFriends,
    fullName,
    generalChannels,
    jobTitle,
    userName,
    phoneNumber,
    residentChannels,
    staff,
    userType,
    workerCategory,
    serviceLevel,
    checkIn,
    room,
  }: UserCreateEditFormValuesLegacy) => {
    const userKind = UserTypeFn.toKind(userType as UserType);
    const isUserResident = userType === 'resident';
    const isCreateAccount = !isUserResident && createAccount;

    const selectedGeneralChannels = isUserResident ? [] : generalChannels;
    const selectedResidentChannels = isUserResident ? [] : residentChannels;
    const selectedFamilyAndFriends = !isUserResident ? [] : familyAndFriends;
    const selectedStaff = !isUserResident ? [] : staff;

    const residentConversationId = IdFn.new();
    const memberRoleId = IdFn.fromString('member');
    const fullNameSplit = fullName?.trim().split(' ');

    const userToUpdate = {
      id,
      orgId,
      email,
      isCreateAccount,
      phoneNumber: PhoneNumberFn.getInternationalNumber(phoneNumber),
      kind: userKind,
      department: department ? department?.trim() : undefined,
      description: description?.trim(),
      fullName: fullName?.trim(),
      // This legacy component is used when org has `removeUsernames` feature flag disabled.
      // In this case, in form, we only have input fields for `fullName` and `userName`.
      // So, we need to split `fullName` to get `firstName` and `lastName`.
      firstName: fullNameSplit && fullNameSplit[0] ? fullNameSplit[0] : undefined,
      lastName:
        fullNameSplit && fullNameSplit.length > 1
          ? fullNameSplit.slice(1).join(' ').trim()
          : undefined,
      jobTitle: jobTitle ? jobTitle?.trim() : undefined,
      userName: userName?.trim(),
      workerCategory: workerCategory ? workerCategory?.trim() : undefined,
      checkInOptOut: !checkIn,
      serviceLevel: !serviceLevel ? ServiceLevel.NONE : EnumFn.parse(ServiceLevel, serviceLevel),
      roomId: room,
      residentChannels: selectedResidentChannels?.map((item) => ({
        conversationId: item.id,
        kind: ConversationKind.CHANNEL,
        subject: ConversationSubjectKind.RESIDENT,
        roleId: memberRoleId,
        userId: id,
      })),
      staff: selectedStaff?.map((item) => ({
        conversationId: residentConversationId,
        kind: ConversationKind.CHANNEL,
        roleId: memberRoleId,
        subject: ConversationSubjectKind.RESIDENT,
        userId: item.id,
      })),
      familyAndFriends: selectedFamilyAndFriends?.map((item) => ({
        conversationId: residentConversationId,
        kind: ConversationKind.CHANNEL,
        roleId: memberRoleId,
        subject: ConversationSubjectKind.RESIDENT,
        userId: item.id,
      })),
      generalChannels: selectedGeneralChannels?.map((item) => ({
        conversationId: item.id,
        kind: ConversationKind.CHANNEL,
        subject: ConversationSubjectKind.GENERAL,
        roleId: memberRoleId,
        userId: id,
      })),
    };

    setIsUpdating(true);

    updateUser({
      input: userToUpdate,
    }).then((result) => {
      setIsUpdating(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error updating user', type: 'error' }));
        if (result.data?.result?.msg === UserErrorMessages.EMAIL_ALREADY_EXISTS) {
          setEmailError(true);
        }
      } else {
        dispatch(snackAdd({ message: 'User successfully updated', type: 'success' }));
        goBack();
      }
    });
  };

  return (
    <UserCreateEditFormLegacy
      isEditMode
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      initialErrors={initialErrors}
      isLoading={fetching || stale}
      isSaving={isUpdating}
      title={`Edit user - ${user?.fullName}`}
      user={user}
      emailError={emailError}
    />
  );
};

export default UserEditDrawerLegacy;
