import * as Sentry from '@sentry/react';
import { Cache } from '@urql/exchange-graphcache';
import { Schema } from '@serenityapp/domain';
import { OrganizationResult, organizationLocationsQuery } from '@serenityapp/api-graphql';
import { DEFAULT_LOCATIONS_PAGE_SIZE } from '../../pages/admin/LocationsPage/utils';

export const getOrganizationLocationsQueryVariables = (organizationId: string) => ({
  getInput: {
    id: organizationId,
  },
  input: {
    kinds: ['LocationGroup', 'Building', 'Unit'],
  },
  pageInput: {
    after: '',
    first: DEFAULT_LOCATIONS_PAGE_SIZE,
  },
});

export const addLocation = (
  location: Schema.Location.ItemWithTypename,
  organizationId: string,
  cache: Cache,
) => {
  const q = {
    query: organizationLocationsQuery,
    variables: getOrganizationLocationsQueryVariables(organizationId),
  };

  const newEdge: Schema.Organization.Locations.Edge = {
    __typename: 'OrganizationLocationEdge',
    organization: { orgId: organizationId, id: organizationId },
    id: `${organizationId}-${location.id}`,
    location,
  };

  cache.updateQuery<OrganizationResult>(q, (data) => {
    if (!data) {
      Sentry.addBreadcrumb({
        message:
          'Failed to get current organization location query data while trying to add a location',
        data: { q },
      });
      return null;
    }
    const updatedEdges = [newEdge, ...data.organization.locations.edges];
    const locations = {
      ...data.organization.locations,
      edges: updatedEdges,
    };
    data.organization.locations = locations;
    return data;
  });
};

export const removeLocations = (
  locationsToRemove: string[],
  organizationId: string,
  cache: Cache,
) => {
  const entityKey = cache.keyOfEntity({ __typename: 'Organization', id: organizationId });
  const allFields = cache.inspectFields(entityKey);

  // Filter all fields on the Organization entity to find location fields
  const locationFields = allFields.filter((field) => field.fieldName === 'locations');

  locationFields.forEach((field) => {
    const arg = field?.arguments || getOrganizationLocationsQueryVariables(organizationId);

    const q = {
      query: organizationLocationsQuery,
      variables: {
        input: arg.input,
        pageInput: arg.pageInput,
        getInput: {
          id: organizationId,
        },
      },
    };

    cache.updateQuery<OrganizationResult>(q, (data) => {
      if (!data) {
        Sentry.addBreadcrumb({
          message:
            'Failed to get current organization location query data while trying to remove a location',
          data: { q },
        });
        return null;
      }

      const cachedEdges = data.organization.locations.edges;

      const updatedEdges = cachedEdges.filter(
        (edge) => !locationsToRemove.includes(edge.location.id),
      );

      // If no changes were made, return the original data
      if (updatedEdges.length === cachedEdges.length) {
        return data;
      }

      data.organization.locations.edges = updatedEdges;
      return data;
    });
  });
};

/**
 * Resolves a field from the cache
 * @param cache
 * @param entity
 * @param field
 */
export function resolveCacheField<T>(
  cache: Cache,
  entity: { __typename: string; id: string },
  field: string,
): T | null {
  return cache.resolve(entity, field) as T | null;
}

export function updateAmountOfUsersInLocation(
  cache: Cache,
  organizationId: string,
  usersIds: Set<string>,
  id: string,
) {
  const entityKey = cache.keyOfEntity({ __typename: 'Organization', id: organizationId });
  const allFields = cache.inspectFields(entityKey);

  const locationFields = allFields.filter((field) => field.fieldName === 'locations');

  locationFields.forEach((field) => {
    const arg = field.arguments || getOrganizationLocationsQueryVariables(organizationId);

    const queryConfig = {
      query: organizationLocationsQuery,
      variables: {
        input: arg.input,
        pageInput: arg.pageInput,
        getInput: { id: organizationId },
      },
    };

    cache.updateQuery<OrganizationResult>(queryConfig, (data) => {
      if (!data) {
        Sentry.addBreadcrumb({
          message: 'Failed to retrieve current organization location data for cache update',
          data: { queryConfig },
        });
        return null;
      }

      const { edges: cachedEdges } = data.organization.locations;

      let isUpdated = false;

      const updatedEdges = cachedEdges.map((edge: Schema.Organization.Locations.Edge) => {
        const location = edge.location;

        if (!location || !location.users || location.id === id) {
          return edge;
        }

        const updatedUserEdges = location.users.edges.filter((userEdge) => {
          return !usersIds.has(userEdge.user.id);
        });

        if (updatedUserEdges.length !== location.users.edges.length) {
          isUpdated = true;
          return {
            ...edge,
            location: {
              ...location,
              users: { ...location.users, edges: updatedUserEdges },
            },
          };
        }

        return edge;
      });

      if (isUpdated) {
        data.organization.locations.edges = updatedEdges;
      }

      return data;
    });
  });
}
