import * as Sentry from '@sentry/react';
import { ClientApi } from '@serenityapp/api-client-graph';
import urqlSchema from '@serenityapp/api-client-graph/generated/urql-schema.json';
import { Schema } from '@serenityapp/domain';
import {
  onErrorExchange,
  serenityAuthExchange,
  analyticsExchange,
} from '@serenityapp/urql-exchanges';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { retryExchange } from '@urql/exchange-retry';
import { Client, CombinedError, fetchExchange } from 'urql';
import {
  buildingCreate,
  buildingLocationGroupConvert,
  buildingRemove,
  conversationCreate,
  locationCreate,
  locationGroupBuildingConvert,
  locationGroupCreate,
  locationGroupRemove,
  unitCreate,
  unitRemove,
  unitUpdate,
  userCreateNext,
} from './urql';
import onePageCacheResolver from './urql/resolvers/onePageCacheResolver';
import { IAnalytics } from '@serenityapp/core';

// Helper function to ensure a cache key is a string
const ensureKey = (x: any): string => (typeof x === 'string' ? x : '');

const cache = cacheExchange({
  schema: urqlSchema,

  /*
   * URQ Docs: Custom cache keys & non-keyable entities
   * @see https://commerce.nearform.com/open-source/urql/docs/graphcache/normalized-caching/#custom-keys-and-non-keyable-entities
   * We set keys to null for entities that do not have a unique id (they are not keyable).
   * This prevents urql from throwing warnings about missing keys.
   */
  keys: {
    UserAccount: () => null,
    Correlation: () => null,
    Address: () => null,
    UserAwaySettings: () => null,
    S3File: () => null,
    ViewerPermissions: () => null,
    CanAct: () => null,
    UserLocator: () => null,
    ZonedDateTime: () => null,
  },
  resolvers: {
    Unit: {
      deviceIds: (parent, args, cache, info) => {
        // If the deviceIds field is not present, return an empty array
        // to prevent errors in the UI while data is being fetched
        return parent.deviceIds || [];
      },
    },
    Query: {
      device: (parent, args: Schema.Device.Get.Variables, cache, info) => {
        return { __typename: 'AlexaDevice', id: args?.input?.id };
      },
      location: (parent, args: Schema.Location.Get.Variables, cache, info) => {
        const typenames = ['Unit', 'Building', 'LocationGroup'];
        for (const typename of typenames) {
          const key = cache.keyOfEntity({ __typename: typename, id: args.input.id });

          if (cache.resolve(key, 'id')) {
            // If the entity with this typename and ID exists in the cache, resolve it
            return {
              __typename: typename,
              id: args.input.id,
            };
          }
        }

        // Fallback if nothing found
        return null;
      },
      conversation: (parent, args: Schema.Conversation.Get.Variables, cache, info) => {
        return { __typename: 'Conversation', id: args?.input?.id };
      },
    },
    Viewer: {
      location: (parent, args: ClientApi.Filter.Api.Get.Variables, cache, info) => {
        return { __typename: 'Location', id: args?.input?.id };
      },
      conversation: (parent, args: ClientApi.Conversation.Api.Get.Variables, cache, info) => {
        return { __typename: 'Conversation', id: args?.input?.conversationId };
      },
      user: (parent, args: ClientApi.User.Api.Get.Variables, cache, info) => {
        return { __typename: 'User', id: args?.input?.userId };
      },
    },
    Organization: {
      locations: onePageCacheResolver,
      devices: (parent, args, cache, info) => {
        const { pageInput } = args;
        const typename = ensureKey(parent.__typename);
        const id = ensureKey(parent.id);
        const organizationKey = cache.keyOfEntity({ __typename: typename, id });

        // Attempt to read the 'devices' data from the cache using the organizationKey.
        // If the data is already cached, cachedResult will contain the cached data.
        const cachedResult = cache.resolve(organizationKey, 'devices');

        // Check if the query is paginated
        if (pageInput) {
          // If the query is paginated
          return cachedResult || onePageCacheResolver(parent, args, cache, info); // Return cached data or use resolver to save data to cache
        } else {
          // If the query is not paginated
          return cachedResult || null; // Return cached data or null if not found
        }
      },
    },
  },
  updates: {
    Mutation: {
      buildingCreate,
      buildingRemove,
      conversationCreate,
      buildingLocationGroupConvert,
      locationGroupRemove,
      locationGroupCreate,
      locationGroupBuildingConvert,
      unitCreate,
      unitRemove,
      unitUpdate,
      userCreateNext,
      // Filters
      locationCreate,
    },
  },
});

export function createClient(graphqlEndpoint: string, analytics: IAnalytics) {
  const client = new Client({
    url: graphqlEndpoint,
    exchanges: [
      // Hooks up the URQL devtools (browser extension)
      devtoolsExchange,
      // Handles client-side cache
      // @urql/exchange-graphcache is a better alternative as it's normalized, but
      // it cannot work with aliased fields out-of-the-box and I didn't have time to
      // fix it yet, so I'm using the default cacheExchange (denormalized) for now.
      cache,
      // Retries the request if it fails
      retryExchange({
        initialDelayMs: 1000,
        maxDelayMs: 6000,
        randomDelay: true,
        maxNumberAttempts: 3,
        retryIf: (err) => err.networkError !== undefined && !isAuthError(err),
      }),
      // Handles analytics
      analyticsExchange(analytics),
      // this needs to be placed ABOVE the authExchange in the exchanges array, otherwise the auth error
      // will show up here before the auth exchange has had the chance to handle it
      onErrorExchange((err, ctx) => {
        Sentry.captureException(err, {
          extra: ctx,
          tags: { urql: 'urql' },
          fingerprint: ['{{ default }}', 'urql', err.message],
        });
      }),
      // Handles authentication
      serenityAuthExchange(),
      // Default "exchange" that just handles sending the request and fetching the response
      fetchExchange,
    ],
  });

  return client;
}

function isAuthError(error: CombinedError): boolean {
  const is401 = error.response?.status === 401;
  const tokenExpired = error.graphQLErrors.some((e) => e.message.includes('Token has expired'));
  return is401 || tokenExpired;
}
