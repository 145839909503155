import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Link,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { UserCreateEditFormValues } from '@serenityapp/components-react-common';
import { AbstractUserProps } from '@serenityapp/domain';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ServiceLevel } from '@serenityapp/core';

interface ServiceLevelMismatchDialogProps {
  locator: AbstractUserProps['locator'];
}

/**
 * This dialog gets open when service level mismatch occur between selected user service level
 * and the service level of unit where the user is assigned.
 * @returns a dialog component
 */
const ServiceLevelMismatchDialog = ({ locator }: ServiceLevelMismatchDialogProps) => {
  const makeTestId = useMakeTestId('ServiceLevelMismatchDialog');
  const mainTestId = makeTestId('');

  const { values, dirty } = useFormikContext<UserCreateEditFormValues>();

  const [shouldOpenMismatchDialog, setShouldOpenMismatchDialog] = useState<boolean>(false);

  const closeDialog = () => {
    setShouldOpenMismatchDialog(false);
  };

  useEffect(() => {
    if (!locator || !locator.serviceLevels || !dirty) {
      return;
    }
    if (
      values.serviceLevel &&
      !locator.serviceLevels.includes(values.serviceLevel as ServiceLevel)
    ) {
      setShouldOpenMismatchDialog(true);
    }

    // We dont want the use effect to run every time the dirty value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.serviceLevel, locator]);

  const unitNameWithType = `${locator?.unitTypeDisplayName} ${locator?.unitName}`;

  const fullLocationPath = [
    locator?.locationGroupName,
    locator?.buildingName,
    locator?.floorName,
    unitNameWithType,
  ]
    .filter(Boolean)
    .join(' / ');

  return (
    <Dialog data-testid={mainTestId} open={shouldOpenMismatchDialog}>
      <DialogTitle>Service level mismatch</DialogTitle>
      <DialogContent>
        <Box>
          The resident's current service level does not match the designated service level for:
        </Box>
        <Box sx={linkSx}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`/admin/locations/${locator?.unitId}`}
            data-testid={makeTestId('unit-link')}
          >
            {fullLocationPath}
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          data-testid={makeTestId('got-it-button')}
          onClick={closeDialog}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const linkSx = {
  mt: 2,
};

export default ServiceLevelMismatchDialog;
